import JshModule from '@core/scripts/helper/jsh-module';
import Slider from '@bonprix/pattern-library/modules/pattern-library/mini-slider.js';

function initSliderModules() {

  do {
    const {moduleScope, config} = JshModule('teaser/teaser-slider');

    new Slider.Module(moduleScope, config); // init slider from pattern library
  } while (document.querySelector('[jsh-module="teaser/teaser-slider"]') !== null);
}

window.addEventListener('DOMContentLoaded', initSliderModules);